import React from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
const API_URL = "https://www.nssplindia.com/backend/api/caseStudy";
const UPLOAD_ENDPOINT = "uploadImage";

const TextEditor = ({ onChange, data }) => {
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                console.log("res", res);
                resolve({
                  default: `${res.imageURL}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <CKEditor
      editor={Editor}
      data={data}
      config={{
        extraPlugins: [uploadPlugin],
        height: 500,
      }}
      onChange={onChange}
      // onChange={(event, editor) => onChange(event, editor)}
    />
  );
};

export default React.memo(TextEditor);
