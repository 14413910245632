import React from "react";
import LogoImage from "../../assets/images/logo.png";

const Header = () => {
  return (
    <div className={"header"}>
      <div className="logo">
        <img src={LogoImage} />
      </div>
    </div>
  );
};

export default React.memo(Header);
