import * as React from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { GetTechCategroy } from "../../../services/projects.handlers";
import Stack from "@mui/material/Stack";
import AddTechCategory from "./AddTechCategory";

const TableRowData = [
  "ID",
  "Name",
  "Action"
];

const TechCategory = () => {
  const [allData, setAllData] = React.useState({
    technologyList: [],
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    const payload = {
      page: 1,
      limit: 1000
    }
    GetTechCategroy(payload).then((response) => {
      if (response.status) {
        setAllData(response.data.data);
      }
    });
  };


  return (
    <DashboardLayout>
      <Stack spacing={2} direction="row" style={{ marginBottom: "30px", justifyContent: "flex-end" }}>
        <AddTechCategory apiCall={() => fetchData()} />
      </Stack>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell padding="checkbox" key={index}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allData.technologyList.length > 0 ? (
              allData.technologyList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell>
                        {page * 10 + index + 1}
                      </TableCell>
                      <TableCell
                        component="td"
                        // id={labelId}
                        scope="row"
                      >
                        {item.name}
                      </TableCell>

                      <TableCell
                        component="td"
                        // id={labelId}
                        scope="row"
                      >

                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow
                hover
                //   onClick={(event) => handleClick(event, row.name)}
                role="checkbox"
                //   aria-checked={isItemSelected}
                tabIndex={-1}
              >
                <TableCell
                  style={{ width: "100%" }}
                  component="td"
                  colSpan={4}
                  align={"center"}
                  // id={labelId}
                  scope="row"
                // padding="checkbox"
                >
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={allData.technologyList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default TechCategory;
