import * as React from "react";
import DashboardLayout from "../../Layout/DashboardLayout";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Alert, InputLabel } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import AddVertical from "./Vertical/AddVertical";
import AddTechnology from "./TechCategory/Technology/AddTechnology";
import TextEditor from "../../Components/TextEditor";
import {
  UpdateCaseStudy,
  GetCaseStudyById,
  GetTechnology,
  GetVertical,
} from "../../services/projects.handlers";
import Loader from "../../Components/Loader";

const EditProjects = () => {
  const [loader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    _id: "",
    projectType: [],
    projects: [],
    title: "",
    country: "",
    deploymentPlatform: "",
    duration: "",
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    image5: "",
    mainimg: "",
    flipimg: "",
    technology: [],
    allTechs: [],
    detail: "",
    problem: "",
    solution: "",
    result: "",
    project_status: "",
    meta_title: "",
    meta_desc: "",
    meta_keywords: "",
    client_desc: "",
  });
  const params = useParams();
  const navigate = useNavigate();
  const [vertical, setVertical] = React.useState([]);
  const [techList, setTechList] = React.useState([]);

  React.useEffect(() => {
    fetchVertical();
    fetchTech();
    getProject();
  }, [params.id]);

  const getProject = () => {
    GetCaseStudyById(params.id).then((response) => {
      if (response.status) {
        let data = response.data.data;
        var techArray = [];
        data.technology.map((item) => {
          techArray = [...techArray, item._id];
        });
        var verticalArray = [];
        data.projectType.map((item) => {
          verticalArray = [...verticalArray, item._id];
        });
        console.log("sadasd");
        setFormData({
          _id: data._id,
          projectType: verticalArray,
          projects: data.projectType,
          // projects:[]:data.
          title: data.title,
          country: data.country,
          deploymentPlatform: data.deploymentPlatform,
          duration: data.duration,
          // image1: data.image1,
          // image2: data.image2,
          // image3: data.image3,
          // image4: data.image4,
          // image5: data.image5,
          // mainimg: data.mainimg,
          // flipimg: data.flipimg,
          technology: techArray,
          allTechs: data.technology,
          detail: data.detail,
          problem: data.problem,
          solution: data.solution,
          result: data.result,
          project_status: data.project_status,
          meta_title: data.meta_title,
          meta_desc: data.meta_desc,
          meta_keywords: data.meta_keywords,
          client_desc: data.client_desc ? data.client_desc : "",
        });
      }
    });
  };

  const fetchVertical = React.useCallback(() => {
    GetVertical({
      page: 1,
      limit: 1000,
    }).then((response) => {
      if (response.status) {
        setVertical(response.data.data.technologyList);
      }
    });
  }, [vertical]);

  const fetchTech = React.useCallback(() => {
    const payload = {
      page: 1,
      limit: 1000,
    };
    GetTechnology(payload).then((response) => {
      if (response.status) {
        setTechList(response.data.data.technologyList);
      }
    });
  }, [techList]);
  const handleSubmit = async () => {
    setLoader(true);
    UpdateCaseStudy(formData).then((response) => {
      if (response.status) {
        alert(response.message);
        setLoader(false);
      }
    });
  };
  console.log("formDataformData", formData);

  return (
    <DashboardLayout>
      <Box component="form">
        <Paper elevation={1} style={{ padding: "20px", background: "none" }}>
          <h3>Edit Projects</h3>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Vertical
                </InputLabel>
                <Autocomplete
                  multiple
                  options={vertical}
                  value={formData.projects}
                  onChange={(event, newValue) => {
                    console.log("newValue", newValue, formData);
                    setFormData({
                      ...formData,
                      projects: newValue,
                      projectType: newValue.map((item) => item._id),
                    });
                  }}
                  getOptionLabel={(option) => option.project_type}
                  filterSelectedOptions
                  name={"vertical"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vertical"
                      placeholder="Vertical"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={2} style={{ margin: "auto" }}>
              <AddVertical apiCall={() => fetchVertical()} />
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Project Name
                </InputLabel>
                <TextField
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  required
                  fullWidth
                  value={formData.title}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="title"
                  label="Project Name"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Country
                </InputLabel>
                <TextField
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  onChange={(e) =>
                    setFormData({ ...formData, country: e.target.value })
                  }
                  required
                  value={formData.country}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  name="title"
                  label="Country"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Duration
                </InputLabel>
                <TextField
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  onChange={(e) =>
                    setFormData({ ...formData, duration: e.target.value })
                  }
                  required
                  value={formData.duration}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  name="duration"
                  label="Duration"
                  variant="outlined"
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Deployment Platform
                </InputLabel>
                <TextField
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      deploymentPlatform: e.target.value,
                    })
                  }
                  required
                  value={formData.deploymentPlatform}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  name="title"
                  label="Deployment Platform"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Home Image 1
                </InputLabel>
                <TextField
                  type={"file"}
                  accept="image/*"
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  onChange={(e) =>
                    setFormData({ ...formData, mainimg: e.target.files[0] })
                  }
                  required
                  fullWidth
                  name="title"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Home Image 2 / Problem Image
                </InputLabel>
                <TextField
                  type={"file"}
                  accept="image/*"
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  onChange={(e) =>
                    setFormData({ ...formData, flipimg: e.target.files[0] })
                  }
                  required
                  fullWidth
                  name="title"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Solution Image
                </InputLabel>
                <TextField
                  type={"file"}
                  accept="image/*"
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  onChange={(e) =>
                    setFormData({ ...formData, image1: e.target.files[0] })
                  }
                  required
                  fullWidth
                  name="title"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Result Image
                </InputLabel>
                <TextField
                  type={"file"}
                  accept="image/*"
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  onChange={(e) =>
                    setFormData({ ...formData, image2: e.target.files[0] })
                  }
                  required
                  fullWidth
                  name="title"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  ScreenShot 1
                </InputLabel>
                <TextField
                  type={"file"}
                  accept="image/*"
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  onChange={(e) =>
                    setFormData({ ...formData, image3: e.target.files[0] })
                  }
                  required
                  fullWidth
                  name="title"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  ScreenShot 2
                </InputLabel>
                <TextField
                  type={"file"}
                  accept="image/*"
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  onChange={(e) =>
                    setFormData({ ...formData, image4: e.target.files[0] })
                  }
                  required
                  fullWidth
                  name="title"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  ScreenShot 3
                </InputLabel>
                <TextField
                  type={"file"}
                  accept="image/*"
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  onChange={(e) =>
                    setFormData({ ...formData, image5: e.target.files[0] })
                  }
                  required
                  fullWidth
                  name="title"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={9}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Technology
                </InputLabel>
                <Autocomplete
                  multiple
                  options={techList}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  value={formData.allTechs}
                  onChange={(event, newValue) => {
                    console.log("newValue", newValue, formData);
                    setFormData({
                      ...formData,
                      allTechs: newValue,
                      technology: newValue.map((item) => item._id),
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Technology"
                      placeholder="Technology"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={3} style={{ margin: "auto" }}>
              <AddTechnology apiCall={() => fetchTech()} />
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Client Description
                </InputLabel>
                <TextEditor
                  data={formData.client_desc}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (formData._id !== "") {
                      setFormData({ ...formData, client_desc: data });
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Detail
                </InputLabel>
                <TextEditor
                  data={formData.detail}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (formData._id !== "") {
                      setFormData({ ...formData, detail: data });
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Problem
                </InputLabel>
                <TextEditor
                  data={formData.problem}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (formData._id !== "") {
                      setFormData({ ...formData, problem: data });
                    }
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Solution
                </InputLabel>
                <TextEditor
                  data={formData.solution}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (formData._id !== "") {
                      setFormData({ ...formData, solution: data });
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Result
                </InputLabel>
                <TextEditor
                  data={formData.result}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (formData._id !== "") {
                      setFormData({ ...formData, result: data });
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Meta Title
                </InputLabel>
                <TextField
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  value={formData.meta_title}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFormData({ ...formData, meta_title: e.target.value })
                  }
                  required
                  fullWidth
                  name="meta_title"
                  label="Meta Title"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Meta Keywords
                </InputLabel>
                <TextField
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  value={formData.meta_keywords}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFormData({ ...formData, meta_keywords: e.target.value })
                  }
                  required
                  fullWidth
                  name="meta_keywords"
                  label="Meta Keywords"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={"form-group"}>
                <InputLabel className={"form-label"} htmlFor="employee_ID">
                  Meta Description
                </InputLabel>
                <TextField
                  // error={errorData.error}
                  // helperText={errorData.errorText}
                  // value={formData.text}
                  value={formData.meta_desc}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFormData({ ...formData, meta_desc: e.target.value })
                  }
                  required
                  fullWidth
                  name="meta_desc"
                  label="Meta Description"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                // style={{ justifyContent: "end" }}
              >
                <Button onClick={() => handleSubmit()} variant="contained">
                  Update Projects
                  {loader && <Loader color={"#fff"} />}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </DashboardLayout>
  );
};

export default EditProjects;
