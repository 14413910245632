import React from "react";
import { Routes, Route } from "react-router";
import LoggedOutRoute from "./loggedOutRoutes";
import LoggedInRoute from "./loggedInRoutes";
import Login from "../Screens/Boarding/Login";
import Dashboard from "../Screens/Dashboard";
import Contact from "../Screens/Contact";
import TechCategory from "../Screens/Projects/TechCategory/TechCategory";
import Technology from "../Screens/Projects/TechCategory/Technology/Technology";
import Projects from "../Screens/Projects";
import AddProjects from "../Screens/Projects/AddProjects";
import EditProjects from "../Screens/Projects/EditProjects";
import Vertical from "../Screens/Projects/Vertical";
import Preview from "../Screens/Projects/Preview";
// import UserInfo from "../Screens/Dashboard/UserInfo";

const AppRoutes = (props) => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <LoggedOutRoute>
            <Login />
          </LoggedOutRoute>
        }
      />
      <Route
        exact
        path="/dashboard"
        element={
          <LoggedInRoute>
            <Dashboard />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/contact"
        element={
          <LoggedInRoute>
            <Contact {...props} />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/techCategory"
        element={
          <LoggedInRoute>
            <TechCategory {...props} />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/technology"
        element={
          <LoggedInRoute>
            <Technology {...props} />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/vertical"
        element={
          <LoggedInRoute>
            <Vertical {...props} />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/projects"
        element={
          <LoggedInRoute>
            <Projects {...props} />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/add_projects"
        element={
          <LoggedInRoute>
            <AddProjects {...props} />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/edit_projects/:id"
        element={
          <LoggedInRoute>
            <EditProjects {...props} />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/preview/:id"
        element={
          <LoggedInRoute>
            <Preview {...props} />
          </LoggedInRoute>
        }
      />
    </Routes>
  );
};
export default React.memo(AppRoutes);
