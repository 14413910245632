export const API_PATH = "https://www.nssplindia.com/backend/api/";
//export const API_PATH = "https://staging.nssplindia.com/backend/api/";
export const API_ROUTE = {
  AUTH: {
    LOGGED_IN: "admin/login",
  },
  TECHNOLOGY: {
    ADD_CATEGORY: "techCategory/addTechCategory",
    GET_CATEGORY: "techCategory/getTechCategory",
    ADD_TECHNOLOGIES: "technology/addTechnologies",
    GET_TECHNOLOGIES: "technology/getTechnologies",
    GET_VERTICAL: "projectType/getProjectType",
    ADD_VERTICAL: "projectType/addProjectType",
  },
  CASE_STUDY: {
    ADD_CASESTUDY: "caseStudy/addCaseStudy",
    GET_CASESTUDY: "caseStudy/getCaseStudy",
    UPDATE_CASESTUDY: "caseStudy/updateCaseStudy",
    GET_CASESTUDY_BYID: "caseStudy/getCaseStudyById",
  },
  CONTACT: {
    GET_CONTACTS: "contactForm/getContactFormDetails",
  },
};
