import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const AddCategoryTech = async (payload) => {
  return await AxiosInstance.post(
    `${API_ROUTE.TECHNOLOGY.ADD_CATEGORY}`,
    payload
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetTechCategroy = async (payload) => {
  return await AxiosInstance.post(
    `${API_ROUTE.TECHNOLOGY.GET_CATEGORY}`,
    payload
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetTechnology = async (payload) => {
  return await AxiosInstance.post(
    `${API_ROUTE.TECHNOLOGY.GET_TECHNOLOGIES}`,
    payload
  )
    .then((response) => response)
    .catch((error) => error);
};

export const AddTech = async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(async function (key) {
    await formData.append(key, data[key]);
  });

  return await AxiosInstance.post(
    `${API_ROUTE.TECHNOLOGY.ADD_TECHNOLOGIES}`,
    formData
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetVertical = async (payload) => {
  return await AxiosInstance.post(
    `${API_ROUTE.TECHNOLOGY.GET_VERTICAL}`,
    payload
  )
    .then((response) => response)
    .catch((error) => error);
};
export const createVertical = async (payload) => {
  return await AxiosInstance.post(
    `${API_ROUTE.TECHNOLOGY.ADD_VERTICAL}`,
    payload
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetCaseStudy = async (payload) => {
  return await AxiosInstance.post(
    `${API_ROUTE.CASE_STUDY.GET_CASESTUDY}`,
    payload
  )
    .then((response) => response)
    .catch((error) => error);
};

export const AddCaseStudy = async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(async function (key) {
    if (Array.isArray(data[key])) {
      await formData.append(key, JSON.stringify(data[key]));
    } else {
      await formData.append(key, data[key]);
    }
  });
  return await AxiosInstance.post(
    `${API_ROUTE.CASE_STUDY.ADD_CASESTUDY}`,
    formData
  )
    .then((response) => response)
    .catch((error) => error);
};

export const UpdateCaseStudy = async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(async function (key) {
    if (Array.isArray(data[key])) {
      await formData.append(key, JSON.stringify(data[key]));
    } else {
      await formData.append(key, data[key]);
    }
  });
  return await AxiosInstance.put(
    `${API_ROUTE.CASE_STUDY.UPDATE_CASESTUDY}`,
    formData
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetCaseStudyById = async (id) => {
  return await AxiosInstance.get(
    `${API_ROUTE.CASE_STUDY.GET_CASESTUDY_BYID}/${id}`
  )
    .then((response) => response)
    .catch((error) => error);
};
