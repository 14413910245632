import React from "react";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import LogoImage from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
const drawerWidth = 240;

const drawerMenu = [
  {
    icon: "Inbox",
    name: "Dashboard",
    route: "/dashboard",
  },
  {
    icon: "Inbox",
    name: "Tech Ctegory",
    route: "/techCategory",
  },
  {
    icon: "Inbox",
    name: "Technology",
    route: "/technology",
  },
  {
    icon: "Inbox",
    name: "Vertical",
    route: "/vertical",
  },
  {
    icon: "Inbox",
    name: "Projects",
    route: "/projects",
  },
  {
    icon: "Inbox",
    name: "Contact",
    route: "/contact",
  },
];
const drawer = (
  <div>
    <Toolbar>
      <Link to={"/"}>
        <img style={{ width: "100px" }} src={LogoImage} />
      </Link>
    </Toolbar>
    <Divider />
    <List>
      {drawerMenu.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemButton component={Link} to={item.route}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </div>
);
const LeftSidebar = () => {
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        // container={container}
        variant="temporary"
        // open={mobileOpen}
        // onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default LeftSidebar;
