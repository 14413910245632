import React from 'react';
import { useParams } from 'react-router';

const Preview = () => {
    const params = useParams();
    return (<div>
        <iframe src={`https://demo.nssplindia.com/projects/${params.id}`} style={{ width: "100%", height: "100vh" }} />
    </div>)
}

export default Preview