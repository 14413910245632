import * as React from "react";
import DashboardLayout from "../../Layout/DashboardLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
const TableRowData = ["ID", "Name", "Email"];

const Dashboard = () => {
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    fetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchData = () => { };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  var i = 1;
  return (
    <DashboardLayout>
      {/* <Stack spacing={2} direction="row" style={{ marginBottom: "30px" }}>
        <TextField style={{ width: "50%" }} label="Search" id="search" />
        <Button variant="outlined">
          <FileUploadIcon /> Bulk Upload
        </Button>
        <Button
          onClick={() => navigate("/add_organization")}
          variant="contained"
        >
          <AddIcon /> Add New Organization
        </Button>
      </Stack> */}
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              {TableRowData.map((item, index) => (
                <TableCell padding="checkbox" key={index}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  i++;
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell style={{ width: "10%" }}>
                        {page * 10 + index + 1}
                      </TableCell>
                      <TableCell
                        style={{ width: "40%" }}
                        component="td"
                        // id={labelId}
                        scope="row"
                      >
                        <Link
                          to={`/user/${item._id}`}
                          component={RouterLink}
                          variant="p"
                          state={{ item }}
                        >
                          {item.name + " " + item.surname}
                        </Link>
                      </TableCell>
                      <TableCell
                        component="td"
                        style={{ width: "50%" }}
                        // id={labelId}
                        scope="row"
                        padding="checkbox"
                      >
                        {item.email}
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow
                hover
                //   onClick={(event) => handleClick(event, row.name)}
                role="checkbox"
                //   aria-checked={isItemSelected}
                tabIndex={-1}
              >
                <TableCell
                  style={{ width: "100%" }}
                  component="td"
                  colSpan={3}
                  align={"center"}
                  // id={labelId}
                  scope="row"
                // padding="checkbox"
                >
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardLayout>
  );
};

export default Dashboard;
