import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from "@mui/icons-material/Add";
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import { AddTech, GetTechCategroy } from '../../../../services/projects.handlers';
import Autocomplete from '@mui/material/Autocomplete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 3,
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddTechnology(props) {
  const item = props.item;
  const [open, setOpen] = React.useState(false)
  const [allData, setAllData] = React.useState({
    technologyList: [],
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
  });
  const [formData, setFormData] = React.useState(
    {
      id: item ? item._id : "",
      cat_id: item ? item.tech_category_id : "",
      cat_name: item ? item.tech_category_id : "",
      text: item ? item.name : "",
      image: item ? item.image : ""
    }
  )
  const [errorData, setErrorData] = React.useState({
    error: false,
    errorText: "",
    errorImage: ""
  })
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const payload = {
      page: 1,
      limit: 1000
    }
    GetTechCategroy(payload).then((response) => {
      if (response.status) {
        setAllData(response.data.data);
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorData({
      error: false,
      errorText: "",
      errorImage: ""
    })
    // console.log(event.target); return
    const data = new FormData(event.currentTarget);
    const payload = {
      name: data.get("techCategory"),
      image: data.get("uploadFile"),
      tech_category_id: formData.cat_id
    }

    if (data.get("techCategory")) {
      if (data.get("uploadFile").name) {
        if (formData.id == null) {
          AddTech(
            payload
          ).then((response) => {
            if (response.status) {
              props.apiCall()
              handleClose()
              alert(response.data.message)
              setFormData({ id: null, text: "" })
            }
          })
        } else {

        }
      } else {
        setErrorData({
          error: true,
          errorImage: "Image is required"
        })
      }

    } else {
      setErrorData({
        error: true,
        errorText: "Field is required"
      })
    }

  }

  return (
    <div>
      {item ? <Button
        onClick={handleOpen}
        variant="contained"
      >
        Edit
        </Button> : <Button
        onClick={handleOpen}
        variant="contained"
      >
        <AddIcon /> Add Technology
        </Button>}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} component="form"
            onSubmit={handleSubmit}>
            <Typography id="transition-modal-title" variant="h6" component="h2" style={{ marginBottom: "20px" }}>
              Technology
            </Typography>
            <Autocomplete
              disablePortal
              style={{ marginBottom: "20px" }}
              onChange={(event, newValue) => {
                console.log("newValue", newValue)
                setFormData({ ...formData, cat_id: newValue._id, cat_name: newValue.name });
              }}
              id="tech-category"
              value={{ _id: formData.cat_id, name: formData.cat_name }}
              options={allData.technologyList}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="TechCategory" />}
            />
            <TextField
              error={errorData.errorText ? errorData.error : false}
              helperText={errorData.errorText}
              value={formData.text}
              onChange={(e) => setFormData({ ...formData, text: e.target.value })}
              required
              fullWidth
              style={{ marginBottom: "20px" }}
              name="techCategory"
              id="tech-category"
              label="Technology"
              variant="outlined" />
            <TextField
              error={errorData.errorImage ? errorData.error : false}
              helperText={errorData.errorImage}
              required
              name="upload-photo"
              type="file"
              name="uploadFile"
            />
            <Stack
              spacing={2}
              direction="row"
              style={{ marginTop: "20px", justifyContent: "flex-end" }}
            >
              <Button
                type="submit"
                variant="contained"
              >
                Add
                </Button>
              <Button
                onClick={handleClose}
                style={{ backgroundColor: "grey" }}
                variant="contained"
              >
                Close
                </Button>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
